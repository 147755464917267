header {
  padding: 58px 0 100px;
}

@media (min-width: 992px) {
  header {
    padding: 60px 0 100px;
  }
}

body,
p,
li {
  font-size: 1.25rem;
  font-weight: 300;
}

nav ul {
  justify-content: space-around;
}

nav li {
  padding-left: 30px;
  padding-right: 30px;
}

h2 {
  margin-bottom: 20px;
}

section {
  padding: 100px 0;
}

#profile {
  padding: 80px;
}

.jumbotron {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  padding-bottom: 2rem;
}

.bg-libs-blue-1 {
  background-color: #93d6df;
}

.bg-libs-blue-2 {
  background-color: #4cb3d2;
}

.bg-libs-blue-3 {
  background-color: #15a1a0;
}

.bg-libs-green-1 {
  background-color: #b0dcc3;
}

.bg-libs-green-2 {
  background-color: #62c294;
}

.bg-libs-green-3 {
  background-color: #0b7b87;
}

section.social {
  padding-top: 10px;
}

.size-32x32 {
  height: 32px;
}

.btn-join {
  background-color: #0b7b87;
  color: #fff;
  font-size: larger;
}

.btn-join:hover {
  color: #ccc;
}

.sticky-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1050;
}

.social-icon {
  height: 30px;
  width: 30px;
  ;
  margin: 5px 5px 5px 5px;
}



@media (min-width: 768px) {
  .sticky-sidebar {
    background: #e7eef1;
    padding: 0;
    box-sizing: border-box;
    background-color: #343a40;
    border-radius: 25px 0px 0px 25px;

    position: fixed;
    top: 100px;
    right: 0;
    width: 75px;
    padding: 15px 10px 5px 10px;
    float: right;
  }

  .social-icon {
    height: 55px;
    width: 55px;
    ;
    margin-bottom: 15px;
  }
}

.small-print p,
.small-print li {
  font-size: 0.8em;
}

.h-600 {
  height: 600px;
}

.board-bio {
  min-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.board-company {
  font-size: 0.9rem;
}

.board-linkedin {
  height: 24px;
  width: 24px;
}

.small-logo {
  height: 80px;
}

.carousel-container {
  width: 100%;
  margin-top: 1rem;
}

.react-multi-carousel-track {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.react-multi-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slide-item {
  height: 100%;
  width: 80%;
  position: relative;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
}

.slide-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity as needed */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-download {
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0.1rem white solid;
  display: none;
}

.card:hover::before {
  opacity: 1;
}

.card:hover .image-download {
  display: block;
}

/* styles.css */
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-img-top {
  height: 200px;
  /* Adjust the height as needed */
  object-fit: cover;
}
